import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'

type TransferFilter = {
  dateRange: string
}
interface IPaymentSliceState {
  transferFilters: TransferFilter
}

const initialState: IPaymentSliceState = {
  transferFilters: {
    dateRange: 'LAST5DAYS'
  }
}

const Slice = createSlice({
  name: '@module/payments',
  initialState,
  reducers: {
    SetTransfersFilter: (state, action: PayloadAction<TransferFilter>) => {
      return { ...state, transferFilters: { ...action.payload } }
    }
  }
})

export const { SetTransfersFilter } = Slice.actions
export const getTransfersFilterState = (state: RootState) => state.modules.payments.transferFilters
export default Slice.reducer
